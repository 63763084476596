export const lang = {
	//title为每个页面的标题
	title: 'Product Detail',
	noticeTag: 'Notice',
	noticeValue: 'Price just for sample. Please contact seller to fetch the actual price.',
	shop: 'StoreName',
	shopSeller: 'Salesman',
	shopSellerPhone: 'Contact',
	count: 'count',
	stock: 'stock',
	buyNow: 'Buy Now',
	addToCart: 'Add To Cart',
	outOfStock: 'Out Of Stock',
	goodsDetail: 'Detail',
	goodsProperty: 'Property',
	goodsComment: 'Comment',
	hotSales: 'Hot Sales',
	recommend: 'Recommend',
	sales: 'volumn',
	views: 'views',
	collect: 'collects',
	brand: 'brand',
	findHots: 'FindHots',
	rank: 'Rank',
	collected: 'Collect',
	brandIndex: 'BrandHome',
}
