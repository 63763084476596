export const lang = {

	//公共
	common: {
		//语言包名称
		name: 'En',
		header_searchInputPlaceholder: 'Please input product keyword',
		header_pleaseLogin: 'Please login',
		header_freeRegistry: 'Registry',
		header_applyEnter: 'SettleIn',
		header_myOrder: 'Order',
		header_memberCenter: 'Vip',
		header_helperCenter: 'Help',
		header_onlineService: 'Service',
		header_phoneVersion: 'Mobile',
		header_cart: 'Cart',
		footer_customerService: 'Customer Service',
		footer_onlineService: 'Service',
		footer_feedback: 'Feedback',
		footer_followUs: 'Follow Us',
		footer_wechatPublic: 'Offical',
		footer_webo: 'WeiBo',
		footer_miniProgram: 'MiniProgram',
		footer_serviceHotline: 'Contact Us',
		footer_contract: 'Online Contract Generate',
		footer_logistics: 'Assured Logistics',
		footer_quality: 'Quality assurance',
		footer_aboutUs: 'About Us',
		footer_helpCenter: 'Help Center',
		footer_afterSell: 'After-Sell Service',
		footer_delivery: 'Delivery and Acceptance',
		footer_cooperation: 'Business Cooperation',
		footer_purchase: 'Purchase',
		footer_openPlatform: 'Open Platform',
		footer_searchRecommend: 'Search Recommend',
		footer_friendshipLink: 'Friendship Link'
	}
}
