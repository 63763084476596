export const lang = {
	//title为每个页面的标题
	title: '商品详情',
	noticeTag: '提醒',
	noticeValue: '页面价格为展示价格，详细报价请于店铺销售联系',
	shop: '店铺',
	shopSeller: '店铺销售',
	shopSellerPhone: '销售电话',
	count: '数量',
	stock: '库存',
	buyNow: '立即购买',
	addToCart: '加入购物车',
	outOfStock: '库存不足',
	goodsDetail: '商品详细',
	goodsProperty: '商品属性',
	goodsComment: '商品评论',
	hotSales: '24小时热销榜',
	recommend: '同类商品推荐',
	sales: '销量人气',
	views: '商品浏览',
	collect: '收藏人气',
	brand: '品牌信息',
	findHots: '查看热销',
	rank: '浏览排名',
	collected: '收藏商品',
	brandIndex: '品牌首页',
}
