export const lang = {
	//公共
	common: {
		//语言包名称
		name: '中文',
		header_searchInputPlaceholder: '请输入商品关键词',
		header_pleaseLogin: '您好，请登录',
		header_freeRegistry: '免费注册',
		header_applyEnter: '申请入驻',
		header_myOrder: '我的订单',
		header_memberCenter: '会员中心',
		header_helperCenter: '帮助中心',
		header_onlineService: '在线客服',
		header_phoneVersion: '手机版',
		header_cart: '我的购物车',
		footer_customerService: '客户服务',
		footer_onlineService: '在线客服',
		footer_feedback: '问题反馈',
		footer_followUs: '关注我们',
		footer_wechatPublic: '公众号',
		footer_webo: '微博',
		footer_miniProgram: '小程序',
		footer_contract: '在线合同生成',
		footer_logistics: '放心物流',
		footer_quality: '苏欧商城品质保证',
		footer_aboutUs: '关于我们',
		footer_helpCenter: '帮助中心',
		footer_afterSell: '售后服务',
		footer_delivery: '配送与验收',
		footer_cooperation: '上午合作',
		footer_purchase: '企业采购',
		footer_openPlatform: '开放平台',
		footer_searchRecommend: '搜索推荐',
		footer_friendshipLink: '友情链接'
	}
}
